<template>
  <div id="requests-list">
    <v-card
      class="mb-6"
    >
      <v-card-title>
        Vaccine Requests
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search Requests"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            rounded
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="requestListTable"
        :options.sync="options"
        :server-items-length="totalRequestListTable"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100]
        }"
        :items-per-page="25"
        show-select
      >
        <!-- Name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <v-img
                v-if="item.corporate.icon"
                :src="require(`@/assets/images/company/${item.corporate.icon}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.corporate.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{name: 'corporate-details', params: { id: item.corporate.id }}"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.corporate.name }}
              </router-link>
              <small>{{ item.corporate.location }}</small>
            </div>
          </div>
        </template>

        <!-- Date -->
        <template #[`item.date`]="{item}">
          <span>{{ formatToDate(item.created_at)}}</span>
        </template>

        <!-- Code -->
        <template #[`item.code`]="{item}">
          <router-link
            :to="{ name: 'corporates-requests-view', params: { id: item.id }}"
            class="text--secondary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.id.split('-')[0].toUpperCase()}}
          </router-link>
        </template>

        <!-- type -->
        <template #[`item.type`]="{item}">
          <span>{{ item.is_csr ? 'CSR Request' : 'Staff Request' }}</span>
        </template>

        <!-- branch -->
        <template #[`item.branch`]="{item}">
          <span>{{ item.location }}</span>
        </template>

        <!-- amount -->
        <template #[`item.amount`]="{item}">
          <span>${{ resolveVaccineAmount(item) }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status == null ? 'Pending' : item.status.name)"
            :class="`${resolveStatusVariant(item.status == null ? 'Pending' : item.status.name)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status.name }}
          </v-chip>
        </template>

        <template
          #[`item.actions`]="{item}"
        >
          <div
            class="d-flex flex-nowrap"
          >
            <v-tooltip
              right
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="info"
                  class="small btn-inline-link"
                  :to="{name: 'corporates-requests-view', params: { id: item.id }}"
                  style="opacity: 0.6px"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                      {{ icons.mdiImageText }}
                    </v-icon>
                </v-btn>

              </template>
              <span>View</span>
            </v-tooltip>

            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                  class="ml-1 opacity-5"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click.stop="() => handleApprovalAndCancellation(item, 'accept')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                      color="success"
                    >
                      {{ icons.mdiFormatListChecks }}
                    </v-icon>
                    <span>Accept</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click.stop="() => handleApprovalAndCancellation(item, 'reject')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                      color="danger"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                    <span>Reject</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  :to="{name: 'corporates-requests-view', params: { id: item.id }}"
                >
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                      color="info"
                    >
                      {{ icons.mdiImageText }}
                    </v-icon>
                    <span>View Details</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!--Dialog Box-->
    <template>
      <v-dialog
        v-model="confirmDialog.visible"
        persistent
        width="500"
      >
        <v-card>
          <v-card-title class="headline">
            {{ confirmDialog.title }}
          </v-card-title>
          <v-card-text>{{ confirmDialog.message }}</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="confirmDialog.visible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :color="confirmDialog.theme"
              @click="confirmDialog.visible = false"
            >
              {{ confirmDialog.button }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {
  mdiExportVariant,
  mdiDotsVertical,
  mdiFormatListBulletedSquare,
  mdiPlaylistCheck,
  mdiFormatListChecks,
  mdiClose,
  mdiImageText,
} from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import { avatarText, formatToDate } from '@core/utils/filter'
import store from '@/store'
import storeModule from '../StoreModule'
import useRequestsList from './useRequestsList'
import resolvers from '@/mixins/resolvers'

export default {
  components: {},
  mixins: [resolvers],
  setup() {
    const VACCINE_REQUEST_MODULE_NAME = 'requests'
    if (!store.hasModule(VACCINE_REQUEST_MODULE_NAME)) store.registerModule(VACCINE_REQUEST_MODULE_NAME, storeModule)

    const {
      requestListTable,
      tableColumns,
      searchQuery,
      totalRequestListTable,
      loading,
      options,
      selectedRows,
      confirmDialog,
      fetchRequests,
      resolveVaccineAmount,
    } = useRequestsList()

    onUnmounted(() => {
      if (store.hasModule(VACCINE_REQUEST_MODULE_NAME)) store.unregisterModule(VACCINE_REQUEST_MODULE_NAME)
    })

    return {
      requestListTable,
      tableColumns,
      searchQuery,
      totalRequestListTable,
      loading,
      options,
      selectedRows,
      confirmDialog,
      avatarText,
      formatToDate,
      fetchRequests,
      resolveVaccineAmount,
      icons: {
        mdiExportVariant,
        mdiDotsVertical,
        mdiFormatListBulletedSquare,
        mdiPlaylistCheck,
        mdiFormatListChecks,
        mdiClose,
        mdiImageText,
      },
    }
  },
  methods: {
    handleApprovalAndCancellation(item, type) {
      if (type == 'accept') {
        this.confirmDialog.title = 'Approve Request'
        this.confirmDialog.theme = 'success'
        this.confirmDialog.button = 'Confirm'
        this.confirmDialog.message = `Confirm approving the request from ${item.corporate.name} for ${item.quantity} vaccine requests. The corporate will be notified`
      } else {
        this.confirmDialog.title = 'Reject Request'
        this.confirmDialog.theme = 'error'
        this.confirmDialog.button = 'Reject'
        this.confirmDialog.message = `Confirm rejecting the request from ${item.corporate.name} for ${item.quantity} vaccine requests. The corporate will be notified`
      }

      this.confirmDialog.request = item
      this.confirmDialog.visible = true
    },
  },
}
</script>

