import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useRequestsList() {
  const requestListTable = ref([])

  const tableColumns = [
    { text: 'CORPORATE', value: 'name', sortable: false },
    { text: 'DATE', value: 'date', sortable: false },
    { text: 'CODE', value: 'code', sortable: false },
    { text: 'TYPE', value: 'type', sortable: false },
    { text: 'BRANCH', value: 'branch', sortable: false },
    { text: 'QUANTITY', value: 'quantity', sortable: false },
    { text: 'AMOUNT', value: 'amount', sortable: false },
    { text: 'STATUS', value: 'status', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalRequestListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['names'],
    sortDesc: [true],
  })
  const selectedRows = ref([])
  let confirmDialog = ref({
    visible: false,
    title: 'Accept Request',
    button: 'Confirm',
    theme: 'success',
    message:
      'Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running',
    request: {},
  })

  //Amount Resolver
  const resolveVaccineAmount = item => {
    var price = item.price ?? 10
    var quantity = item.quantity ?? 0

    if (price == 0) price = 10
    return (price * quantity).toString().toAccounting()
  }

  String.prototype.toAccounting = function() {
    return parseFloat(this)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  }

  //Get Requests
  const fetchRequests = () => {
    store
      .dispatch('requests/fetchRequests', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        requestListTable.value = response.data['result']
        totalRequestListTable.value = 17

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchRequests()
  })

  return {
    requestListTable,
    tableColumns,
    searchQuery,
    totalRequestListTable,
    loading,
    options,
    selectedRows,
    confirmDialog,
    fetchRequests,
    resolveVaccineAmount,
  }
}
